import {  createRouter, createWebHashHistory } from "vue-router";
import store from "./store/store";
const routes =  [
  //MC
  {
    path: "/getallcoursemc",
    name: "getallcoursemc",
    component: () => import("./components/learningspaces/GetAllCourseMC"),
    meta: {
      breadcrumb: () => "Courses - Competencies"
    }
  },
  {
    path: "/managemcs",
    name: "managemcs",
    component: () => import("./components/learningspaces/ManageMCs"),
    meta: {
      breadcrumb: () => store.state.courseTitle || "Course Details"
  }
  },
  //KCs 
  {
    path: "/getcourselist",
    name: "getcourselist",
    component: () => import("./components/learningspaces/GetAllCourseKC"),
    meta: {
      breadcrumb: () => "Courses - Knowledge Checks"
    }
  },
  {
    path: "/managekcs",
    name: "managekcs",
    component: () => import("./components/learningspaces/ManageKCs"),
    meta: {
      breadcrumb: () => (store.state.courseTitle + " - " + store.state.firstName) || "Course Details"
    }
  },
  {
    path: "/assessmentlist",
    name: "assessmentlist",
    component: () => import("./components/assessment/AssessmentList"),
    meta: {
      breadcrumb: () => "Assessments"
    }
  },
  //prompts
  // {
  //   path: "/getcourselistforprompts",
  //   name: "getcourselistforprompts",
  //   component: () => import("./components/prompts/GetAllPrompts")
  // },
  {
    path: "/showallprompts",
    name: "showallprompts",
    component: () => import("./components/prompts/ShowAllPrompts"),
    meta: {
      breadcrumb: () => "Prompts"
    }
  },
  //questions 
  {
    path: "/getprecannedquestions",
    name: "getprecannedquestions",
    component: () => import("./components/questions/GetPreCannedQuestions"),
    meta: {
      breadcrumb: () => "Questions"
    }
  },
  //guardrails
  {
    path: "/getprecannedrails",
    name: "getprecannedrails",
    component: () => import("./components/guardrails/ManageGR.vue"),
    meta: {
      breadcrumb: () => "Guardrails"
    }
  },
  //chat
  {
    path: "/qna",
    name: "qna",
    component: () => import("./components/chat/QnAComponent"),
    meta: {
      breadcrumb: () => "ChatBot"
    }
  },
  //editor
  {
    path: '/editor/:contentId',
    name: 'EditorComponent',
    component: () => import("./components/learningspaces/EditorComponent"),
    props: true,
    meta: {
      breadcrumb: () => {
        const prevPath = store.state.breadcrumbHistory[store.state.breadcrumbHistory.length - 1]?.to;

        if(prevPath && prevPath.includes("managekcs")){
          const tabName = store.state.kcActiveTab === "kcExplanation" ? "KC Explanation" : "Answer Analysis";
          return `${store.state.selectedEntityName || 'AI Content'} - ${tabName}`;
        }
        return "AI Content" + (store.state.selectedEntityName ? " - " + store.state.selectedEntityName : "");
      }
    }
  },
  //login
  {
    path: "/login",
    name: "login",
    component: () => import("./components/auth/LoginUser"),
    meta: {
      breadcrumb: () => "Login"
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./components/auth/LogoutUser"),
    meta: {
      breadcrumb: () => "Logout"
    }
  },
  //archived content
  {
    path: "/archivedcontent/:courseId/:entityId",
    name: "archivedContent",
    component: () => import("./components/learningspaces/ArchivedContent"),
    props: true,
    meta: {
      breadcrumb: () => store.state.selectedEntityName ? 'Archive - ' + store.state.selectedEntityName : "Archived Content"
    }
  },
  // Default route for root path
  {
    path: "/",
    name: "root",
    redirect: () => {
      // Assuming you store auth state in localStorage or Vuex
      const isLoggedIn = !!store.state.token
      return isLoggedIn ? '/getallcoursemc' : '/login';
    }
  },
  //Email testing
    {
      path: "/sendeEmail",
      name: "sendeEmail",
      component: () => import("./components/mail/EMail"),
      meta: {
        breadcrumb: () => "Email Testing"
      }
    },
    //Author Aide
    {
      path: "/aide",
      name: "aide",
        component: () => import("./components/author/AuthorsAide"),
      meta: {
        breadcrumb: () => "Author Aide"
      }
    },
    //kcexplanation
    {
      path: "/kcexplanation",
      name: "kcexplanation",
      component: () => import("./components/learningspaces/GetAllKCExplanation"),
      meta: {
        breadcrumb: () => "KC Explanation"
      }
    },
    {
      path: "/chatpoc",
      name: "chatpoc",
      component: () => import("./chatpoc/layouts/Layout"),
      meta: {
        breadcrumb: () => "Chat POC"
      },
      children: [
        {
          path: "",
          name: "chatpoc_landing",
          component: () => import("./chatpoc/views/LandingPageView"),
        },
        {
          path: "chat",
          name: "chatpoc_chat",
          component: () => import("./chatpoc/views/ChatView"),
        },
        {
          path: "upload",
          name: "chatpoc_upload",
          component: () => import("./chatpoc/views/CoursesView"),
        },
        {
          path: 'course-list/:pathMatch(.*)*',
          name: "chatpoc_courselist",
          component: () => import("./chatpoc/views/CourseListView"),
        },
      ]
    },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;