//Pragya Client App
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store.js'
import { createI18n } from 'vue-i18n';
import messages from './common/messages.json'; 
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'

// For Global CSS
import './common/global.css'

// PrimeVue icons and CSS
import 'primevue/resources/themes/lara-light-teal/theme.css'
import 'primeicons/primeicons.css'
import Toast from 'primevue/toast';

const i18n = createI18n({
  locale: 'en', // Default locale
  fallbackLocale: 'en', // Fallback locale
  messages: {
    en: messages, // Messages imported from messages.json
  }, // Messages imported from messages.json
});

// Global navigation guard
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');

    if (!token && to.name !== 'login') {
      next({ name: 'login' }); // Redirect to login page if token is missing and route is not login
      return;
    } 

    if(from.name && (to.path === '/getallcoursemc' || to.path === '/getcourselist' || to.path === '/login' || to.path === '/logout' || to.path === '/showallprompts' || to.path === '/getprecannedquestions' || to.path === '/getprecannedrails' || to.path === '/aide' || to.path === '/sendeEmail' || to.path === '/kcexplanation')) {
      store.commit('clearBreadcrumbs');
    }

    if(to.meta.breadcrumb) {
      store.commit('addBreadcrumb', { 
          to: to.path,
          query: to.query,
          label: to.meta.breadcrumb(to)
      });
  }

    next();
  });

  const app = createApp(App)

  // Configure PrimeVue and its services
  app.use(PrimeVue)
  app.use(ToastService)
  
  // Register PrimeVue components globally
  app.component('Toast', Toast)
  
  // Mount the application with all other plugins
  app.use(router)
     .use(store)
     .use(i18n)
     .mount('#app')