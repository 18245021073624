import api from "../api/api";

const store = {
  namespaced: true,
  state: {
    // `chat` state
    chat: {
      isGeneratingResponse: false,
      messages: [],
      sessionIds: {},
      selectedModels: [],
      action: "retrieve_and_generate",
      adminPrompt: "",
      models: [
        {
          id: "anthropic.claude-3-haiku-20240307-v1:0",
          name: "Claude 3 Haiku",
        },
        {
          id: "anthropic.claude-3-sonnet-20240229-v1:0",
          name: "Claude 3 Sonnet",
        },
        { id: "meta.llama3-8b-instruct-v1:0", name: "Llama 3 8B Instruct" },
        { id: "meta.llama3-70b-instruct-v1:0", name: "Llama 3 70B Instruct" },
        // {
        //   id: "anthropic.claude-3-5-sonnet-20240620-v1:0",
        //   name: "Claude 3.5 Sonnet",
        // },
        // { id: "mistral.mixtral-8x7b-instruct-v0:1", name: "Mixtral 8x7B Instruct" },
        // { id: "amazon.titan-embed-text-v1", name: "Titan Embeddings G1 - Text" },
        // { id: "amazon.titan-embed-text-v2:0", name: "Titan Text Embeddings V2" },
      ],
      actions: [
        { value: "retrieve_and_generate", name: "Retrieve and Generate" },
        { value: "retrieve", name: "Retrieve" },
      ],
      error: null,
    },

    // `course` state
    course: {
      isFetchingDetails: false,
      error: null,
      courses: [],
      selectedCourse: "",
    },
  },

  mutations: {
    // `chat` mutations
    setIsGeneratingResponse(state, generating) {
      state.chat.isGeneratingResponse = generating;
    },
    clearChat(state) {
      state.chat.messages = [];
    },
    // addMessageByUser(state, { message }) {
    //   state.chat.messages.push({ message, role: 'user' });
    // },
    // addMessageByAi(state, { responses }) {
    //   state.chat.messages.push({ responses, role: 'ai'});
    // },
    addMessage(state, { prompt, responses = [], role }) {
      if (role === "user") {
        // Push a user message with empty responses
        state.chat.messages.push({ prompt, role, responses: [] });
      } else if (role === "ai") {
        // Push an AI response for the prompt
        const lastUserMessage =
          state.chat.messages[state.chat.messages.length - 1];
        if (lastUserMessage && lastUserMessage.role === "user") {
          // Append the AI responses to the last user message
          lastUserMessage.responses = responses;
        } else {
          console.error(
            "No matching user message found to attach AI responses."
          );
        }
      }
    },
    setSessionId(state, { modelId, sessionId }) {
      state.chat.sessionIds[modelId] = sessionId;
    },
    setSessionIds(state, ids){
      state.chat.sessionIds = ids
    },
    setAction(state, action) {
      state.chat.action = action;
    },
    setSelectedModels(state, selectedModels) {
      state.chat.selectedModels = selectedModels; // Update selected models
    },
    setError(state, error) {
      state.chat.error = error;
    },
    setAdminPrompt(state, prompt) {
      state.chat.adminPrompt = prompt;
    },

    // `course` mutations
    setIsFetchingDetails(state, loading) {
      state.course.isFetchingDetails = loading;
    },
    setCourses(state, courses) {
      state.course.courses = courses;
    },
    addCourse(state, course) {
      state.course.courses.push({ value: course, name: course });
    },
    setCourse(state, course) {
      state.course.selectedCourse = course;
    },
  },

  actions: {
    // `chat` actions
    setIsGeneratingResponse({ commit }, generating) {
      commit("setIsGeneratingResponse", generating);
    },
    clearChat({ commit }) {
      commit("clearChat");
    },
    addMessage({ commit }, { message, role }) {
      commit("addMessage", { message, role });
    },
    setSessionId({ commit }, id) {
      // Validate the session ID if necessary
      if (id && typeof id === "string") {
        commit("setSessionId", id);
      } else {
        console.error("Invalid session ID:", id);
      }
    },
    setAction({ commit }, action) {
      commit("setAction", action);
    },
    setSelectedModels({ commit }, models) {
      commit("setSelectedModels", models);
    },
    setError({ commit }, error) {
      commit("setError", error);
    },
    async generateResponses({ commit, state }, prompt) {
      // Add the user message first
      commit("addMessage", { prompt, role: "user" });

      commit("setIsGeneratingResponse", true);
      try {
        const responses = await Promise.all(
          state.chat.selectedModels.map(async model => {
            const requestBody = {
              modelId: model.id,
              prompt,
              action: state.chat.action,
              course: state.course.selectedCourse,
              adminPrompt: state.chat.adminPrompt,
            };
            const modelSessionId = state.chat.sessionIds[model.id];
            if (modelSessionId) {
              requestBody.sessionId = modelSessionId;
            }

            const res = await api.post(
              process.env.VUE_APP_AWS_AI_RESPONSE,
              requestBody
            );
            const data = JSON.parse(res.data.body);

            let responseMessage;
            if (state.chat.action === "retrieve") {
              responseMessage = data.texts.flat().join("\n");
            } else if (state.chat.action === "retrieve_and_generate") {
              responseMessage = data.generated_text;
            } else {
              responseMessage = "Error in generating response.";
            }

            if (res.data.error) {
              throw new Error(res.data.error);
            }

            return {
              modelId: model.id,
              message: responseMessage,
              sessionId: data.session_id,
              resources: data.resources
            };
          })
        );
        // Add the AI responses to the corresponding user message
        const aiResponses = responses.map(({ modelId, message, resources }) => ({
          modelId,
          message,
          resources
        }));
        commit("addMessage", { responses: aiResponses, role: "ai" });

        // Store the session IDs for each model
        responses.forEach(({ modelId, sessionId }) => {
          commit("setSessionId", { modelId, sessionId });
        });
      } catch (error) {
        commit("setError", error.message || "An error occurred");
      } finally {
        commit("setIsGeneratingResponse", false);
      }
    },

    // `course` actions
    async fetchCourses({ commit }) {
      try {
        commit("setIsFetchingDetails", true);
        const response = await api.get(process.env.VUE_APP_AWS_GET_S3_FOLDERS);
        const parsedBody = JSON.parse(response.data.body);

        if (parsedBody?.error) {
          commit("setError", parsedBody.error);
          return;
        }

        const courses = parsedBody?.folders.map((item) => ({
          value: item,
          name: item,
        }));

        commit("setCourses", courses);
        commit("setCourse", courses[0]?.value || "");

        return courses;
      } catch (error) {
        commit("setError", error.message);
      } finally {
        commit("setIsFetchingDetails", false);
      }
    },
  },
};

export default store;