// toastService.js
import { useToast } from 'primevue/usetoast';

class ToastService {
    constructor() {
        this.toast = null;
    }

    // Initialize the toast instance
    initToast() {
        this.toast = useToast();
    }

    // Method to show a toast
    showToast(severity, summary, detail, life = 3000) {
        if (!this.toast) {
            console.error("Toast instance is not initialized. Call `initToast()` first.");
            return;
        }
        this.toast.add({
            severity,
            summary,
            detail,
            life,
        });
    }
}

// Create and export a singleton instance

export default new ToastService();
