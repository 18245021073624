<template>
    <Breadcrumb :model="breadcrumbHistory" class="px-0 py-2 mb-4 border-b text-sm sticky top-16 lg:top-[60px] bg-white z-10">
        <template #item="{ item, props }">
        <router-link v-slot="{ href, navigate }" :to="item.to" custom>
            <a :href="href" v-bind="props.action" @click="navigate">
                <span :class="[
                    'text-black hover:text-primary hover:underline truncate',
                    { 'font-bold': isLastItem(item) }
                ]">{{ item.label }}</span>
            </a>
        </router-link>
        </template>
        <template #separator> <i class="pi pi-angle-right"></i> </template>
    </Breadcrumb>
</template>

<script>
import Breadcrumb from 'primevue/breadcrumb';

export default {
    components: {
        Breadcrumb
    },
    computed: {
        breadcrumbHistory() {
            return this.$store.state.breadcrumbHistory;
        }
    },
    methods: {
        isLastItem(item) {
            return this.breadcrumbHistory.indexOf(item) === this.breadcrumbHistory.length - 1;
        }
    }
}
</script>
