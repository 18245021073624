<template>
    <div class="relative" ref="menuContainer">
        <div ref="magicLine"
            class="magic-line absolute bottom-0 h-0.5 bg-primary transition-all duration-300 ease-in-out" :style="{
                transform: `translateX(${magicLinePosition.left}px)`,
                width: `${magicLinePosition.width}px`,
                opacity: isHovering || shouldShowMagicLine ? 1 : 0
            }"></div>
        <div class="flex items-center">
            <router-link v-for="(link, index) in links" :key="index" :to="link.to"
                class="nav-link px-4 py-2 rounded-md transition-colors duration-300"
                :class="{ 'text-primary': isLinkActive(link.to) }" @mouseenter="updateMagicLine($event.target)"
                @mouseleave="resetMagicLinePosition" @click="setActiveLinkElement($event.target)" ref="navLinks">
                {{ link.label }}
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AnimatedNavLinks',
    props: {
        links: {
            type: Array,
            required: true,
            validator: (value) => {
                return value.every(link => 
                    typeof link.to === 'string' && 
                    typeof link.label === 'string'
                );
            }
        }
    },
    data() {
        return {
            magicLinePosition: {
                left: 0,
                width: 0,
            },
            activeLink: null,
            activeLinkElement: null,
            resetTimeoutId: null,
            isHovering: false,
        }
    },
    computed: {
        shouldShowMagicLine() {
            // Check if current route exists in navigation links
            return this.links.some(link => link.to === this.$route.path);
        }
    },
    beforeCreate() {
    // Add navigation guard to the router
    this.$router.beforeEach((to) => {
      this.activeLink = to.path;
      this.activeLinkElement = this.$refs.navLinks.find((link) =>
        link.$el.getAttribute('href') === `#${to.path}`
      );
    });
  },
  mounted() {
    this.$nextTick(() => {
      // Wait for DOM to be updated
      this.initializeMagicLine();
      window.addEventListener(
        "resize",
        this.debounce(this.initializeMagicLine, 100)
      );
    });
  },
  beforeUnmount() {
    window.removeEventListener(
      "resize",
      this.debounce(this.initializeMagicLine, 100)
    );
  },
  methods:{
    setActiveLink(link) {
      this.activeLink = link;
    },
    setActiveLinkElement(element) {
      this.activeLinkElement = element;
    },
    debounce(fn, delay) {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), delay);
      };
    },
    isLinkActive(path) {
      return this.$route.path === path;
    },
    initializeMagicLine() {
      this.updateMagicLinePosition(this.activeLinkElement);
    },
    updateMagicLinePosition(element) {
      if (!element || !this.$refs.menuContainer) return;
      try {
        const menuRect = this.$refs.menuContainer.getBoundingClientRect();
        const linkRect = element.$el
          ? element.$el.getBoundingClientRect()
          : element.getBoundingClientRect();
        this.magicLinePosition = {
          left: linkRect.left - menuRect.left,
          width: linkRect.width,
        };
      } catch (error) {
        console.warn("Error updating magic line position:", error);
        this.magicLinePosition = { left: 0, width: 0 };
      }
    },
    updateMagicLine(element) {
      // Clear any existing timeout
      if (this.resetTimeoutId) {
        clearTimeout(this.resetTimeoutId);
        this.resetTimeoutId = null;
      }
      
      this.isHovering = true;
      this.updateMagicLinePosition(element);
    },
    resetMagicLinePosition() {
      this.isHovering = false;
      
      // Only reset if we're not hovering over any link
      if (this.resetTimeoutId) {
        clearTimeout(this.resetTimeoutId);
      }
      
      this.resetTimeoutId = setTimeout(() => {
        if (!this.isHovering) {
          // Only reset if we're still not hovering
          if (this.activeLinkElement) {
            this.updateMagicLinePosition(this.activeLinkElement);
          }
          else {
            // Reset magic line position to 0 when not on a nav route
            this.magicLinePosition = { left: 0, width: 0 };
          }
        }
      }, 100);
    },
  },
  watch:{
    $route(){
      this.initializeMagicLine();
    }
  }


}
</script>
