<template>
  <div class="min-h-screen">
    <Toast />
    <Navbar />
    <div class="max-w-screen-xl mx-auto p-4 sm:p-6 md:p-10">
      <Breadcrumb v-if="isLoggedIn && !$route.path.includes('chatpoc')"/>
      <router-view />
    </div>
  </div>
</template>

<script>
import Toast from 'primevue/toast';
import Navbar from './components/navigation/Navbar.vue';
import ToastService from './services/ToastService'
import Breadcrumb from './components/navigation/Breadcrumb.vue';
export default {
  name: "app",
  components: {
    Navbar,
    Toast,
    Breadcrumb
  },
  computed: {
    isLoggedIn() {
      return !!this.$store.state.token;
    },
  },
  created() {
    // Check token on page load
    if (!this.isLoggedIn) {
      // Redirect to login page if token is not present
      this.$router.push({ name: "login" });
    }
  },
  mounted(){
    ToastService.initToast();
  },
  methods: {
    reloadApp() {
      // Reload the entire application to trigger re-evaluation of computed properties or methods
      window.location.reload();
    },
  },
  watch: {
    $route(to, from) {
      if (to.name === undefined || from.name === undefined) {
        return;
      }

      // Reload App.vue component when route changes
      //console.log('Route changed');
      //console.log('to: ', to);
      //console.log('from: ', from);  
      //also check if to and from are null
      //TODO: Confirm if this is needed
      // if (to.name !== from.name) {
      //   this.reloadApp();
      // }

    },
  },
};

</script>