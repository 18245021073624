<template>
    <nav class="sticky top-0 z-50">
        <!-- Desktop -->
        <template class="bg-white shadow-sm px-4 py-3 hidden lg:flex items-center gap-2">
            <div class="flex-1 flex items-center gap-4">
                <router-link to="/" class="flex items-center" :class="{ 'mx-auto *:h-8': !isLoggedIn }">
                    <img src="@/assets/logo.png" alt="PRAGYA-AI" class="h-8 w-auto" />
                </router-link>
                <AnimatedNavLinks :links="links" v-if="isLoggedIn" />
            </div>
            <div v-if="institutionLogo && isLoggedIn" class="flex items-center justify-center mx-auto">
                <router-link to="/">
                    <img :src="institutionLogo" alt="Institution Logo" class="h-8 w-auto" />
                </router-link>
            </div>
            <div class="flex items-center">
                <router-link v-if="isLoggedIn" to="/logout" class="nav-link-logout">Logout</router-link>
                <!-- <router-link v-else to="/login" class="nav-link">Login</router-link> -->
            </div>
        </template>
        <!-- Mobile and Tablets -->
        <template class="bg-white shadow-sm px-4 py-3 flex justify-between lg:hidden items-center gap-2">

            <router-link to="/" class="flex items-center" :class="{ 'mx-auto *:h-12': !isLoggedIn }">
                <img src="@/assets/logo.png" alt="PRAGYA-AI" class="h-10 w-auto" />
            </router-link>

            <Button 
                v-if="isLoggedIn"
                type="button" 
                icon="pi pi-bars"
                @click="toggle"
                aria-haspopup="true" 
                aria-controls="overlay_menu"
                class="p-button-text border-none"
            />
            <Menu 
                v-if="isLoggedIn"
                ref="menu" 
                id="overlay_menu" 
            :model="links" 
                :popup="true"
                class="w-60 !left-auto !right-7 !top-12 text-sm"
            >
                <template #item="{ item, props }">
                    <router-link 
                        :to="item.to" 
                        class="flex items-center p-2 pl-4 w-full hover:bg-surface-100 transition-colors duration-200" 
                        v-bind="props.action"
                    >
                        <i v-if="item.icon" :class="item.icon" class="mr-2"></i>
                        <span>{{ item.label }}</span>
                    </router-link>
                </template>
                <template #end v-if="isLoggedIn">
                    <router-link 
                        to="/logout"
                        class="relative overflow-hidden w-full border-0 bg-transparent flex items-start p-2 pl-4 hover:bg-surface-100 rounded-none cursor-pointer transition-colors duration-200"
                    >
                        <span>Logout</span>
                    </router-link>
                </template>
            </Menu>
        </template>
    </nav>
</template>

<script>
import AnimatedNavLinks from './AnimatedNavLinks.vue';
import Menu from 'primevue/menu';
import Button from 'primevue/button';
export default {
    name: 'Navbar',
    components: {
        AnimatedNavLinks,
        Menu,
        Button
    },
    data() {
        return {
            links: [
                { label: "Competencies", to: "/getallcoursemc", icon: 'pi pi-book', },
                // {
                //     label: 'KC Explanation',
                //     icon: 'pi pi-info-circle',
                //     to: '/kcexplanation'
                // },
                {
                    label: 'KC',
                    icon: 'pi pi-list',
                    to: '/getcourselist'
                },
                {
                    label: 'Prompts',
                    icon: 'pi pi-comments',
                    to: '/showallprompts'
                },
                {
                    label: 'Questions',
                    icon: 'pi pi-question-circle',
                    to: '/getprecannedquestions'
                },
                {
                    label: 'Guardrails',
                    icon: 'pi pi-shield',
                    to: '/getprecannedrails'
                },
                {
                    label: 'Mail',
                    icon: 'pi pi-envelope',
                    to: '/sendeEmail'
                },
                {
                    label: 'Author-Aide',
                    icon: 'pi pi-user-edit',
                    to: '/aide'
                },
                {
                    label: 'Chat POC',
                    icon: 'pi pi-comment',
                    to: '/chatpoc'
                }
            ],
        };
    },
    computed: {
        isLoggedIn() {
            // return !!localStorage.getItem('token');
            return !!this.$store.state.token;
        },
        institutionLogo() {
            return this.$store.state.institutionLogo;
        },
    },
    methods: {
        toggle(event) {
            this.$refs.menu.toggle(event);
        }

    }


}
</script>